import React from "react";
import { Link } from "gatsby";
import { useSelector, useDispatch } from "react-redux";

import { SwitchTransition, Transition } from "react-transition-group";
import gsap from "gsap";

import eventBus from "../../../assets/scripts/utils/eventBus";

import {
  checkQuantity,
  increaseQuantity,
  decreaseQuantity,
} from "../../../assets/scripts/store/redux-slices/cart";

import "../../../assets/styles/components/utils/atoms/button.scss";


const QuantitySwitcher = ({ type, product, qnt }) => {
  const [quantity, setQuantity] = React.useState(1);
  const [currentProduct, setCurrentProduct] = React.useState(undefined);
  const products = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (quantity < 0) {
      setQuantity(1);
    }
  }, [quantity]);

  React.useEffect(() => {
    dispatch(checkQuantity());
    setCurrentProduct(products.find((x) => x.id === product));
    if (currentProduct) {
      if (currentProduct.quantity) {
        setQuantity(currentProduct.quantity);
      }
    }
  }, [products]);

  if (!product) {
    return (
      <div className="bi-button bi-quantity" data-value={quantity}>
        <button
          className="bi-quantity_decrement"
          onClick={() => {
            if (quantity > 1) {
              setQuantity(quantity - 1);
            }
          }}
        >
          <span>&#8722;</span>
        </button>
        <SwitchTransition mode="out-in">
          <Transition
            key={quantity + "trans"}
            timeout={150}
            onEnter={(node) => {
              gsap.from(node, { translateY: 16, opacity: 0 });
            }}
            onExit={(node) => {
              gsap.to(node, { translateY: -16, opacity: 0 });
            }}
          >
            <p className="bi-quantity_value">{quantity}</p>
          </Transition>
        </SwitchTransition>

        <button
          className="bi-quantity_increment"
          onClick={() => {
            setQuantity(quantity + 1);
          }}
        >
          <span>&#43;</span>
        </button>
      </div>
    );
  } else {
    return (
      <div className="bi-button bi-quantity" data-value={quantity}>
        <button
          className="bi-quantity_decrement"
          onClick={() => {
            if (quantity > 0) {
              dispatch(decreaseQuantity({ id: product }));
            }
          }}
        >
          <span>&#8722;</span>
        </button>
        <SwitchTransition mode="out-in">
          <Transition
            key={quantity + "trans"}
            timeout={150}
            onEnter={(node) => {
              gsap.from(node, { translateY: 16, opacity: 0 });
            }}
            onExit={(node) => {
              gsap.to(node, { translateY: -16, opacity: 0 });
            }}
          >
            <p className="bi-quantity_value">
              {currentProduct ? currentProduct.quantity : 0}
            </p>
          </Transition>
        </SwitchTransition>
        <button
          className="bi-quantity_increment"
          onClick={() => {
            dispatch(increaseQuantity({ id: product }));
          }}
        >
          <span>&#43;</span>
        </button>
      </div>
    );
  }
};

const Button = (props) => {
  const button = React.useRef();
  React.useEffect(()=>{
    if(!props.type){
      eventBus.on("addCartProduct", (quantity) => { 
        console.log("This is button", button)
        button.current.querySelector('.bi-button-product-added').innerHTML = '+'+quantity;
        gsap.fromTo(button.current.querySelector('.bi-button-product-added'), {opacity: 0}, {opacity: 1, duration: "300ms"});
        gsap.fromTo(button.current.querySelector('.bi-button-product-added'), {top: "50%"}, {top: "-50%", onComplete: ()=>{
          gsap.fromTo(button.current.querySelector('.bi-button-product-added'), {opacity: 1}, {opacity: 0, duration: "600ms"});
        }});       
      })
    }
  }, [])
  switch (props.type) {
    case "text":
      return (
        <p
          {...props}
          className={
            "bi-button-text " + (props.className ? props.className : "")
          }
        >
          {props.children}
        </p>
      );
      break;
    case "link":
      return (
        <Link className="bi-button" {...props}>
          <span className="bi-button-mask">
            {" "}
            <span className="bi-button-value">{props.children}</span>
          </span>
        </Link>
      );
      break;
    case "submit":
      return (
        <button className="bi-button" {...props}>
          {" "}
          <span className="bi-button-mask">
            {" "}
            <span className="bi-button-value">
              {props.children || props.value}
            </span>
          </span>
        </button>
      );
      break;
    case "quantity":
      return (
        <QuantitySwitcher product={props.product ? props.product.id : null} />
      );
    default:
      return (
        <button ref={button} className="bi-button" {...props}>
          <span className="bi-button-mask">
            {" "}
            <span className="bi-button-value">{props.children}</span>
          </span>
          <span className="bi-button-product-added">+1</span>
        </button>
      );
      break;
  }
};

export const FilterButton = (props) => {
  switch (props.type) {
    case "big":
      return (
        <button className="bi-button bi-button-filter_big" {...props}>
          <span>{props.children}</span>
          {props.close ? (
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6.85714H6.85714V12H5.14286V6.85714H0V5.14286H5.14286V0H6.85714V5.14286H12V6.85714Z"
                fill="#1D1D1D"
              />
            </svg>
          ) : (
            <svg
              width="12"
              height="2"
              viewBox="0 0 12 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.85714 1.85711H12V0.142822H6.85714H5.14286H0V1.85711H5.14286H6.85714Z"
                fill="#1D1D1D"
              />
            </svg>
          )}
        </button>
      );
      break;
    case "small":
      return (
        <button
          className={
            "bi-button bi-button-filter_small" +
            (props.close || props.filtered ? " open" : " close")
          }
          {...props}
        >
          <span className="small">{props.children}</span>
          {props.close ? (
            <svg
              width="12"
              height="6"
              viewBox="0 0 12 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0L6 6L12 0H0Z" fill="#1D1D1D" />
            </svg>
          ) : (
            <svg
              width="12"
              height="6"
              viewBox="0 0 12 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 6L6 -5.24537e-07L0 6L12 6Z" fill="#1D1D1D" />
            </svg>
          )}
        </button>
      );
      break;

    case "small-filter":
      return (
        <button
          className={
            "bi-button bi-button-filter_small" +
            (props.close ? " open" : " close")
          }
          {...props}
        >
          <span className="small">{props.children}</span>
          {!props.close ? (
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 6.85714H6.85714V12H5.14286V6.85714H0V5.14286H5.14286V0H6.85714V5.14286H12V6.85714Z"
                fill="#1D1D1D"
              />
            </svg>
          ) : (
            <svg
              width="12"
              height="2"
              viewBox="0 0 12 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 1.85714H6.85714H5.14286H0V0.142853H5.14286L6.85714 0.142857L12 0.142853V1.85714Z"
                fill="#FFF5E5"
              />
            </svg>
          )}
        </button>
      );
    default:
      return (
        <button className="bi-button" {...props}>
          {props.children}
        </button>
      );
      break;
  }
};

export default Button;
