import gsap from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const imagesParallax = () => {
  // Select all picture elements
  const pictures = document.querySelectorAll("picture");
  // Loop through each picture element
  pictures.forEach((picture) => {
    // Get all data attributes
    const dataAttributes = picture.dataset;
    gsap.killTweensOf(picture);
    gsap.killTweensOf(picture.querySelector("img"));
    if (
      dataAttributes.hasOwnProperty("parallax") &&
      dataAttributes.parallax === "true"
    ) {
      gsap.fromTo(
        picture.querySelector("img"),
        {
          scale: parseFloat(dataAttributes.pScale)
            ? parseFloat(dataAttributes.pScale)
            : 1.2,
          y:
            parseFloat(dataAttributes.pVitesse) *
            (dataAttributes.pDirection === "bottom" ? -100 : 100),
        },
        {
          scale: parseFloat(dataAttributes.pScale)
            ? parseFloat(dataAttributes.pScale)
            : 1.2,
          y:
            parseFloat(dataAttributes.pVitesse) *
            (dataAttributes.pDirection === "bottom" ? 100 : -100),
          scrollTrigger: {
            trigger: picture,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
          },
        }
      );
      if (dataAttributes.pRotate) {
        gsap.fromTo(
          picture,
          {
            rotation: 0,
          },
          {
            rotation: dataAttributes.pRotate
              ? parseFloat(dataAttributes.pRotate)
              : 0,
            scrollTrigger: {
              trigger: picture,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        );
      }
    }
  });
};

const blockParallax = () => {
  // select all elements with data-parallax attribute
  const elements = document.querySelectorAll("[data-parallax='true']");
  // remove all picture elements from the array
  elements.forEach((element) => {
    const dataAttributes = element.dataset;
    if (element.tagName === "PICTURE") {
      return;
    } else {
      gsap.killTweensOf(element);
      if (dataAttributes.pDirection === "side") {
        let offsetX = offsetValue(element, "x");
        gsap.fromTo(
          element,
          {
            x:
              parseFloat(dataAttributes.pVitesse) * offsetX +
              window.innerWidth / 6,
            rotation: 0,
          },
          {
            x:
              parseFloat(dataAttributes.pVitesse) *
              (dataAttributes.pDirection ===
                "bottom" * offsetX - window.innerWidth / 6),
            scrollTrigger: {
              trigger: element,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        );
      } else {
        let offsetY = offsetValue(element, "y");
        gsap.fromTo(
          element,
          {
            y:
              parseFloat(dataAttributes.pVitesse) *
              (dataAttributes.pDirection === "bottom"
                ? offsetY + -100
                : offsetY + 100),
            rotation: 0,
          },
          {
            y:
              parseFloat(dataAttributes.pVitesse) *
              (dataAttributes.pDirection === "bottom"
                ? offsetY + 100
                : offsetY + -100),
            rotation: dataAttributes.pRotate
              ? parseFloat(dataAttributes.pRotate)
              : 0,
            scrollTrigger: {
              trigger: element,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        );
      }
    }
  });
};

const offsetValue = (el, axe) => {
  let value = gsap.getProperty(el, axe) ? gsap.getProperty(el, axe) : 0;
  // return el.dataAttributes.pOffset !== 'false'  || el.dataAttributes.pOffset !== false ? value : 0;

  return 0;
};

export default {
  imagesParallax,
  blockParallax,
  offsetValue,
};
