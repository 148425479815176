import React from "react";

import "../../../assets/styles/components/utils/atoms/scroll-bar.scss";
import eventBus from "../../../assets/scripts/utils/eventBus";

const ScrollBar = () => {
    const [totalHeight, setTotalHeight] = React.useState(0);
    const [thumbHeight, setthumbHeight] = React.useState(0);
    const [isDragging, setIsDragging] = React.useState(false);
    const [scrollStartPosition, setScrollStartPosition] = React.useState(0)
    const [w, setWindow] = React.useState(null)
    const [scrollTop, setScrollTop] = React.useState(0)
    const [isShow, setIsShow] = React.useState(false)
    
    React.useEffect(()=>{

        setWindow(window);


        setTotalHeight(document.documentElement.scrollHeight);
        
        eventBus.on("checkColorHeader", () => {
            setTotalHeight(document.documentElement.scrollHeight);
        });
        window.addEventListener('resize',()=>{
            setTotalHeight(document.documentElement.scrollHeight);
        })

        eventBus.on("scrollStart", ()=>{
            setIsShow(true)
        })
        eventBus.on("scrollStop", ()=>{
            setIsShow(false)
        })
        eventBus.on("routeChange", ()=>{
            setIsShow(false)
        })
        eventBus.on("pageTransitionEnd", ()=>{
            setIsShow(true)
        })
        eventBus.on("loaderEnd", ()=>{
            setIsShow(true)
        })
        
    },[])

    React.useEffect(()=>{

        const sb = document.querySelector(".bi-scroll-bar");
        if(isShow){
            sb.style.opacity = 1
            sb.style.display = "block"
        }else{
            sb.style.opacity = 0
            setTimeout(()=>{
                sb.style.opacity = "none"
            }, 300)
        }

    },[isShow])


    React.useEffect(()=>{
        const biScrollBarClick = document.querySelector(".bi-scroll-bar-click");

        setthumbHeight(window.innerHeight * (window.innerHeight-2) / document.documentElement.scrollHeight);

        biScrollBarClick.style.height = `${thumbHeight}px`
        window.lenis.on('scroll', ({ scroll, limit, velocity, direction, progress }) => {
            if(!isDragging){
                biScrollBarClick.style.transform = `translateY(${( progress * (window.innerHeight - thumbHeight - 2))}px)` 
            }
            biScrollBarClick.style.opacity = 1
        })

    },[totalHeight])


    React.useEffect(() => {
        document.addEventListener('mousemove', dragging);
        document.addEventListener('mouseup', dragEnd);
        return () => {
          document.removeEventListener('mousemove', dragging);
          document.removeEventListener('mouseup', dragEnd);
        };
      }, [isDragging]);


    const dragStart = (e)=>{
        e.preventDefault();
        e.stopPropagation();

        setIsDragging(true) 

        setScrollStartPosition(e.clientY);
        setScrollTop(window.lenis.targetScroll);

        const biScrollBarClick = document.querySelector(".bi-scroll-bar-click");
        biScrollBarClick.style.transition = 0
        
    }

    const dragEnd = (e)=>{
        e.preventDefault();
        e.stopPropagation();


        if(isDragging)
            setIsDragging(false) 


        const biScrollBarClick = document.querySelector(".bi-scroll-bar-click");
        biScrollBarClick.style.transition = 0;
    }
    


    const dragging = (e)=>{
        e.preventDefault();
        e.stopPropagation();

        if (isDragging) {
    
            const deltaY = (e.clientY - scrollStartPosition) * (window.innerHeight / thumbHeight);
            const newScrollTop = Math.min(
              scrollTop + deltaY,
              totalHeight - window.innerHeight
            );

            if(newScrollTop >= 0)
                window.lenis.scrollTo(newScrollTop)
        }


    }

    const onclick = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        const biScrollBarClick = document.querySelector(".bi-scroll-bar-click");

        const trackBottom = biScrollBarClick.getBoundingClientRect().top + thumbHeight/2;

        const clickRatio = trackBottom / window.innerHeight;
        const scrollAmount = Math.floor(clickRatio * totalHeight);

        window.lenis.scrollTo(scrollAmount);
    }

    return (
        <div className={`${w ? (window?.lenis?.isScrolling ? "scroll" : "") : ""} bi-scroll-bar`}>
            <div className="bi-scroll-bar-click" 
                onMouseDown={dragStart}
                >
            </div>
        </div>
    )
}

export default ScrollBar; 