import { Link, navigate } from "gatsby";
import React from "react";
import { useDispatch, useSelector, } from "react-redux";
import { useRef } from "react";

import "../../../assets/styles/components/modules/products/product-list-item.scss";
import urlBuilderImage from "../../../assets/scripts/utils/urlImageBuilder";

const ProductListItem = ({ product, type, dataFirst, dataLast, locationSlot }) => {
  const { data } = useSelector((state) => state.authentication);
  const ProductLink = useRef();
  if (data.is_pro && product.pro_price) {
    product.price = product.pro_price;
  }
  const slug = type ? product.slug : "/store/" + product.slug.current;
  const name = type ? product.name : product.name;
  const price = type ? product.price : product.price;
  const cover = type ? product.asset.path : product.cover.asset.path;
  const crop = type ? product.crop : product.cover.crop;
  const asset = type ? product.asset : product.cover.asset;
  if (product) {
    return (
      <Link
        to={slug}
        data-first={dataFirst && true}
        data-last={dataLast && true}
        ref={ProductLink}
        onClick={(e) => {
          if (window.innerWidth < 770 && locationSlot === 'infinite') {
            e.preventDefault();
          }
        }}
        onTouchEnd={(e) => {
          if (window.innerWidth < 770 && locationSlot === 'infinite') {
            e.preventDefault();
            setTimeout(() => {
              console.log("Le produit", ProductLink.current.dataset.drag)
              if (!ProductLink.current.dataset.drag) {
                navigate(slug);
                //alert(false);
              }
            }, 200)
          }
        }}
      >
        <figure
          className="bi-product-list-item"
          data-product-type={product.type}
        >
          <div className="bi-product-list-item_cover">
            <picture>
              <img
                src={urlBuilderImage(cover, 410, null, {
                  ...(product.cover ? product.cover.crop : crop),
                  ...(product.cover ? product.cover.asset : asset),
                })}
                alt={name}
              />
            </picture>
          </div>
          <figcaption>
            <p>
              {" "}
              <span className="bi-product-list-mask">
                {" "}
                <span className="bi-product-list-value desktop">
                  {name.substring(0, 28) + (name.length <= 28 ? "" : "...")}
                </span>
                <span className="bi-product-list-value mobile">
                  {name.substring(0, 22) + (name.length <= 22 ? "" : "...")}
                </span>
              </span>
            </p>
            <p>{price + "€"}</p>
          </figcaption>
        </figure>
      </Link>
    );
  } else {
    <>
      <p>Une erreur est peut-être survenue</p>
    </>;
  }
};

export default ProductListItem;
