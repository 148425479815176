import React from "react";
import Lenis from "@studio-freight/lenis";

function useSmoothScroll() {
  const [lenis, setLenis] = React.useState(null);

  React.useLayoutEffect(() => {
    if (window.lenis) return window.lenis;
    const l = new Lenis({
      duration: 1.6,
      easing: (t) => ( Math.sqrt(1 - Math.pow(t - 1, 2)) ), // https://easings.net/
      smooth: true,
      direction: "vertical",
      // wrapper: document.querySelector("body"), // element that has overflow
    });

    setLenis(l);

    const scrollFn = (time) => {
      l.raf(time);
      requestAnimationFrame(scrollFn);
    };


    requestAnimationFrame(scrollFn);

    window.lenis = l;

    l?.stop();
    return lenis?.destroy();
  }, []);

  return lenis;
}

export default useSmoothScroll;
