import React from 'react';
import { Link } from 'gatsby';

import '../../../assets/styles/components/modules/crushes/crush-event-list-item.scss';

import urlImageBuilder from '../../../assets/scripts/utils/urlImageBuilder';

const CrushListItem = ({crush, type, mobileWidth, desktopWidth, mobileHeight, desktopHeight}) => {
    // console.log('Console.log crush:', crush, desktopWidth, desktopHeight);
    if (type === 'search') {
        crush.cropData = {...crush.crop, ...crush.asset};
    }
    return (
        <>
            <Link to={type ? crush.slug : '/coups-de-coeur/' + crush.slug}>
                <figure className={'bi-crush-item ' + ' ' + (crush.author === 'Camille' && 'blue_type ') + ' ' + (crush.author === 'Jean-Louis' && 'red_type ') + ' ' + (crush.author === 'Jonathan' && 'orange_type ') + ' ' + (crush.author === 'Maxime' && 'yellow_type ') }>
                    <picture>
                        <source srcSet={urlImageBuilder(crush.picture.replace('image-', '').replace('-jpg', '.jpg'), desktopWidth * 2, desktopHeight * 2, crush.cropData) + ' 2x,' + urlImageBuilder(crush.picture.replace('image-', '').replace('-jpg', '.jpg'), desktopWidth, desktopHeight, crush.cropData) + ' 1x'} />
                        <img src={urlImageBuilder(crush.picture.replace('image-', '').replace('-jpg', '.jpg'), desktopWidth, desktopHeight, crush.cropData)} alt={crush.name} />
                    </picture>
                    {type && <figcaption>{crush.name}</figcaption>}
                </figure>
            </Link>
        </>
    )
}

export default CrushListItem;