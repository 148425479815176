module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.svg","icons":[{"src":"src/asset/images/icons/maskable_icon.png","sizes":"1024x1024","type":"image/png","purpose":"any maskable"}],"name":"Librairie Expérience","short_name":"Lib Expérience","start_url":"/","background_color":"#FFF5E5","theme_color":"#79C5D7","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"51f3fb4eaf7794bb2da6823d9ce31776"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
