import React from "react";
import { Link } from "gatsby";

import "../../../assets/styles/components/modules/cart/product.scss";

const Product = (props) => {
  return (
    <div className="bi-cart-product">
      <picture>
        <img
          src={
            props.product
              ? props.product.imageUrl
                ? props.product.imageUrl
                : "null"
              : "null"
          }
          alt={
            props.product ? props.product.name + "Image du produit" : "Image"
          }
        />
      </picture>
      <div className="bi-cart-product_details">
        <div className="bi-cart-product_details--content">
          {props.link && (
            <Link
              to={"/store/" + (props.product && props.product.slug)}
            >
              {props.product ? props.product.name : "default name"}
            </Link>
          )}
          {!props.link && (
            <p>{props.product ? props.product.name : "default name"}</p>
          )}
          <p>
            {props.product && props.product.price
              ? props.product.price + " €"
              : " "}
          </p>
        </div>
        <div className="bi-cart-product_details--plugins">{props.children}</div>
      </div>
    </div>
  );
};

export default Product;
