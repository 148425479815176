import React from "react";

import "../../../assets/styles/components/utils/atoms/marquee.scss";
//1- Importing animation lib like GSAP etc ...
import gsap from "gsap";
import eventBus from "../../../assets/scripts/utils/eventBus";
import ScrollTrigger from "gsap/src/ScrollTrigger";

function useWindow() {
  const [isWindow, setIsWindow] = React.useState(false);

  React.useEffect(() => {
    setIsWindow(true);

    return () => setIsWindow(false);
  }, []);

  return isWindow;
}

const Marquee = (props) => {
  const marquee = React.useRef(null);
  const isWindow = useWindow();

  let childrens = [];
  for (let index = 0; index < parseInt(props.number); index++) {
    childrens.push(props.children);
  }

  React.useEffect(() => {
    const marqueeInit = () => {
      if (marquee.current) {
        let trackWidth = marquee.current
          .querySelector(".track:nth-child(1)")
          .getBoundingClientRect().width;
        let trackElements = marquee.current.querySelectorAll(".track");
        gsap.killTweensOf(trackElements);
        gsap.to(trackElements, {
          x: -trackWidth,
          ease: "none",
          repeat: -1,
          duration: 60,
        });
      }
    };

    eventBus.on("pageTransitionEnd", () => {
      marqueeInit();
    });
    eventBus.on("loaderEnd", () => {
      marqueeInit();
    });
    document.addEventListener("resize", () => {
      marqueeInit();
    });
  }, []);

  return (
    <div
      className={`bi-marquee bi-title-secondary    ${
        props.isCrush ? props.isCrush : ""
      }`}
      ref={marquee}
    >
      <div
        className={`track-wrapper `}
        data-parallax
        data-p-vitesse="0.3"
        data-p-direction="side"
        data-p-offset="false"
      >
        <div className="track">
          {childrens.map((child, index) => {
            return (
              <span className="word" key={index}>
                {child + " "}
              </span>
            );
          })}
        </div>
        <div className="track">
          {childrens.map((child, index) => {
            return (
              <span className="word" key={index}>
                {child + " "}
              </span>
            );
          })}
        </div>
        <div className="track">
          {childrens.map((child, index) => {
            return (
              <span className="word" key={index}>
                {child + " "}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
