import { Link } from "gatsby";
import React from "react";

import "../../../assets/styles/components/modules/events/event-list-item.scss";

import urlImageBuilder from "../../../assets/scripts/utils/urlImageBuilder";

const EventListItem = ({
  event,
  type,
  mobileWidth,
  desktopWidth,
  mobileHeight,
  desktopHeight,
}) => {
  const slug =
    type === "search" ? event.slug : "/evenements/" + event.slug.current;
  const name = type === "search" ? event.name : event.name;
  const cover = type === "search" ? event.asset.path : event.cover.asset.path;
  const crop = type === "search" ? event.crop : event.cover.crop;
  const asset = type === "search" ? event.asset : event.cover.asset;
  const date = type === "search" ? event.date : event.year;
  return (
    <Link to={slug}>
      <figure className="bi-event-item">
        <picture>
          <source
            media="(max-width: 767px)"
            srcSet={urlImageBuilder(
              cover.replace("image-", "").replace("-jpg", ".jpg"),
              mobileWidth,
              mobileHeight,
              {
                ...(event.cover ? event.cover.crop : crop),
                ...(event.cover ? event.cover.asset : asset),
              }
            )}
          />
          <source
            media="(min-width: 768px)"
            srcSet={urlImageBuilder(
              cover.replace("image-", "").replace("-jpg", ".jpg"),
              desktopWidth,
              desktopHeight,
              {
                ...(event.cover ? event.cover.crop : crop),
                ...(event.cover ? event.cover.asset : asset),
              }
            )}
          />
          <img
            src={urlImageBuilder(
              cover.replace("image-", "").replace("-jpg", ".jpg"),
              desktopWidth,
              desktopHeight,
              {
                ...(event.cover ? event.cover.crop : crop),
                ...(event.cover ? event.cover.asset : asset),
              }
            )}
            alt={name}
          />
        </picture>
        <figcaption>
          {type !== "search" && (
            <>
              <h3 className="bi-title-quaternary">{name}</h3>
              <p>{date}</p>
            </>
          )}
          {type === "search" && (
            <>
              <p>{name}</p>
              <p>{date}</p>
            </>
          )}
        </figcaption>
      </figure>
    </Link>
  );
};

export default EventListItem;
