import React from "react";
import Logo from "../../utils/atoms/Logo";
import gsap from "gsap";

import "../../../assets/styles/components/modules/biAnimations/loader.scss";
import eventBus from "../../../assets/scripts/utils/eventBus";

import imagesLoaded from "imagesloaded";

const Loader = () => {
  const [isMobile, setMobile] = React.useState(false);
  const [visibility, setVisibility] = React.useState(true);

  React.useEffect(() => {
    if (window.innerWidth > 768) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  }, []);

  React.useEffect(() => {
    const desktopLogoPosition = {
      top: 24,
      left: 40,
    };

    const mobileLogoPosition = {
      top: 20,
      left: 20,
    };

    let tl = gsap.timeline();
    tl.fromTo(
      ".bi-loader-logo .bi-logo",
      {
        y: isMobile ? 40 : 80,
      },
      {
        delay: 0.2,
        y: 0,
        onStart:()=>{
          setTimeout(()=>{
            eventBus.dispatch("scrollStop");
          }, 100)
        }
      }
    );

    imagesLoaded("main", () => {
      eventBus.dispatch("scrollStop");
      tl.to(".bi-loader-layer", {
        height: 0,
        stagger: {
          amount: 0.5,
          from: "end",
        },
        duration: 1,
        delay: 0.5,
        ease: "power2.out",
        onComplete: () => {
          const LoaderLogoPosition = () => {
            let logo = document.querySelector(".bi-loader-logo .bi-logo");
            let logoPosition = logo.getBoundingClientRect();
            let logoPositionTop = logoPosition.top;
            let logoPositionLeft = logoPosition.left;

            return {
              top: logoPositionTop,
              left: logoPositionLeft,
            };
          };
          if (window.innerWidth > 768) {
            gsap.to(".bi-loader-logo .bi-loader-logo_mask", {
              x: -(
                LoaderLogoPosition().left -
                (isMobile ? mobileLogoPosition.left - 20 : desktopLogoPosition.left)
              ),
              y: -(
                LoaderLogoPosition().top -
                (isMobile ? mobileLogoPosition.top - 20 : desktopLogoPosition.top)
              ),
              ease: "power2.inOut",
              duration: 0.5,
            });
          } else {
            let logo = document.querySelector('.bi-loader-logo_mask');
            gsap.to(".bi-loader-logo .bi-loader-logo_mask", {x: -(logo.getBoundingClientRect().left - 11.5), y: -(logo.getBoundingClientRect().top - 7.25)})
          }
        },
        onStart: () => {
          eventBus.dispatch("loaderEnd");
        }
      });

      tl.fromTo(
        ".bi-layout-content",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          delay: 1.5,
          onComplete: () => {
            // document.querySelector(".bi-loader")?.remove();
            setVisibility(false);
            eventBus.dispatch("scrollStart");
            window.biLibLoaded = true; 
            window.dispatchEvent(new Event("resize")); 
          },
        }
      );
    });
  }, [/** isMobile */]);

  return (
    <>
      {visibility && (
        <div className="bi-loader">
          <div className="bi-loader-layers">
            <div className="bi-loader-layer bi-loader-layer-1"></div>
            <div className="bi-loader-layer bi-loader-layer-2"></div>
            <div className="bi-loader-layer bi-loader-layer-3"></div>
            <div className="bi-loader-layer bi-loader-layer-4"></div>
          </div>
          <div className="bi-loader-logo">
            <div className="bi-loader-logo_mask">
              <Logo />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
