import React from "react";
import { useSelector, useDispatch } from "react-redux";
import gsap from "gsap";
import eventBus from "../../../assets/scripts/utils/eventBus";
import "../../../assets/styles/components/utils/cookies.scss";
import Button from "../atoms/Button";

import { setModal } from "../../../assets/scripts/store/redux-slices/modals";
import { setCookies } from "../../../assets/scripts/store/redux-slices/userSettings";
import { useVerifAuthentication } from "../../../assets/scripts/store/verifAuthentication";

const Cookies = () => {
  const cookie = useSelector((state) => state.modals.cookies);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const dispatch = useDispatch();

  const handleCookie = (value) => {
    function setCookie(cName, cValue, expDays) {
      let date = new Date();
      date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
      const expires = "expires=" + date.toUTCString();
      document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }

    function delCookie(cName) {
      document.cookie =
        cName + "=;" + "expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
    }

    // Here change google state functionement
    // Add a cookies and disable others
    if (value === 'blocked') {
      eventBus.dispatch("consentG");
      setCookie("lib-experience-cookie", "blocked", 30);
      // Delete existing gtag cookie 
      let allCookies = document.cookie;
      let splittedCookie = allCookies.split(";").map((el) => {
        let split = el.split("=");

        return { name: split[0], value: split[1] };
      });

      splittedCookie.forEach((el) => {
        if (el.name.includes("_ga")) {
          delCookie(el.name);
        }
      });

      // Show to gtag we want to diable it 
      window['ga-disable-G-BRSJWPZ9FZ'] = true;
      window.dataLayer = []; // Delete dataLayer to block communictaion and event trigger width google 
    }
    if (value === true) {
      setCookie("lib-experience-cookie", true, 30);
    }
    // hide popup
    let tl = gsap.timeline();
    tl.fromTo(
      ".bi-cookies p, .bi-cookies .bi-button",
      { opacity: 1, translateY: 0 },
      { opacity: 0, translateY: 10, stagger: 0.15 }
    );
    tl.fromTo(
      ".bi-cookies",
      { opacity: 1, scale: 1, translateY: 0 },
      {
        opacity: 0,
        translateY: 100,
        duration: 1,
        onComplete: () => {
          // change state
          dispatch(setCookies(value));
          dispatch(setModal("cookies"));
        },
      },
      "<"
    );
  };
  
  React.useEffect(() => {
    const getCookie = (cName) => {
      const name = cName + "=";
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded.split("; ");
      let res;
      cArr.forEach((val) => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      });
      return res;
    };
    // verify if cooking is existing
    eventBus.on("loaderEnd", () => {
      if (getCookie("lib-experience-cookie") === 'blocked') {
        handleCookie('blocked');
        return;
      }

      if (getCookie("lib-experience-cookie") === 'true' || getCookie("lib-experience-cookie") === true){
        return;
      }

      setIsLoaded(true);
      
    });
  }, []);

  React.useEffect(() => {
    if (!cookie && isLoaded) {
      let tl = gsap.timeline();
      tl.fromTo(
        ".bi-cookies p, .bi-cookies .bi-button",
        { opacity: 0, translateY: 10 },
        { opacity: 1, translateY: 0, stagger: 0.15 }
      );
      tl.fromTo(
        ".bi-cookies",
        { opacity: 0, translateY: 100 },
        {
          opacity: 1,
          translateY: 0,
          duration: 0.8,
        },
        "<"
      );
    }
  }, [cookie, isLoaded]);

  if (cookie || !isLoaded) {
    return <></>;
  } else {
    return (
      <div className="bi-cookies">
        <p>
          En poursuivant votre navigation sur ce site, vous acceptez
          l’utilisation de cookies et traceurs servant à mesurer l’audience, et
          améliorer notre site internet.
        </p>
        <div className="bi-cookies-manage">
          <p
            className="small"
            onClick={() => {
              handleCookie('blocked');
            }}
          >
            Continuer sans accepter
          </p>
          <Button
            className="bi-button yellow"
            onClick={() => {
              handleCookie(true);
            }}
          >
            J'accepte
          </Button>
        </div>
      </div>
    );
  }
};

export default Cookies;
