import React from "react";
import { SwitchTransition, Transition } from "react-transition-group";

import "../../../assets/styles/components/modules/biAnimations/pagetransition.scss";

import gsap from "gsap";
import eventBus from "../../../assets/scripts/utils/eventBus";
import imagesLoaded from "imagesloaded";

const transitionsData = {
  default: {
    duration: 600,
    onEnter: (node) => {
      if (typeof window != "undefined" && window.biLibLoaded === true) {
        window.lenis.start();
        window.lenis.scrollTo(0, { duration: 0.0001 });
        window.scrollTo(0,0);
        let tl = gsap.timeline();
        imagesLoaded("main", () => {
          tl.set(".bi-transition", { opacity: 1 });
          tl.fromTo(
            ".bi-transition",
            { top: 0, rotate: 0 },
            {
              top: "-140vh",
              rotate: 4,
              onStart: () => {
                eventBus.dispatch("checkColorHeader");
                eventBus.dispatch("pageTransitionEnd");
              },
              onComplete: () => {
                setTimeout(()=>{
                  eventBus.dispatch("scrollStart");
                  window.lenis.start();
                }, 100)
              },
            }
          );
        });
      }
    },
    onExit: (node, startLocation) => {
        let tl = gsap.timeline({
          onComplete: () => {
            setTimeout(() => {
              eventBus.dispatch("scrollStop");
            });
          },
        });
        gsap.killTweensOf(".bi-transition");
        tl.set(".bi-transition", { opacity: 1 });
        tl.fromTo(
          ".bi-transition",
          { top: "130vh", rotate: -2 },
          { top: 0, rotate: 0, duration: 0.3 }
        );
        tl.to(".bi-transition", {
          top: 0,
          rotate: 0,
        });
    },
  },
  crush: {
    duration: 20000,
    onEnter: () => {},
    onExit: () => {},
  },
};
const PageTransition = ({ children, location }) => {
  const [startLocation, setStartLocation] = React.useState(null);

  React.useEffect(() => {
    eventBus.on("routeChange", () => {
      setStartLocation(window.scrollY);
    });
  }, []);
  return (
    <>
      <SwitchTransition mode={"out-in"}>
        <Transition
          key={location}
          unmountOnExit
          timeout={transitionsData.default.duration}
          onEnter={(node) => {
            transitionsData.default.onEnter(node);
          }}
          onExit={(node) => {
            transitionsData.default.onExit(node, startLocation);
          }}
        >
          {children}
        </Transition>
      </SwitchTransition>
      <div className="bi-transition">
        <div className="bi-transition-layer"></div>
      </div>
    </>
  );
};

export default PageTransition;
