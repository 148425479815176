import React from "react";
import { Link, navigate } from "gatsby";
import "../../assets/styles/components/modules/header.scss";
import gsap from "gsap";
import { useSelector, useDispatch } from "react-redux";
import {
  setCart,
  setMenu,
  setSearch,
} from "../../assets/scripts/store/redux-slices/modals";

import {
  checkQuantity,
  setTotalPrice,
} from "../../assets/scripts/store/redux-slices/cart";

import Logo from "../utils/atoms/Logo";
import IconButton from "../utils/atoms/IconButton";
import eventBus from "../../assets/scripts/utils/eventBus";

import useSmoothScroll from "../../assets/scripts/utils/hooks/useSmoothScroll";

const CartIcon = ({ quantity, header }) => {
  const animatePuce = () => {
    let top = window.pageYOffset || document.documentElement.scrollTop;
    let positionButton = document
      .querySelector(".bi-product-cart .bi-button.yellow")
      .getBoundingClientRect();
    let positionIconCart = header.current
      .querySelector(".bi-icon-puce")
      .getBoundingClientRect();
    let topOffset = positionButton.top - positionIconCart.top;
    let leftOffset = positionButton.left - positionIconCart.left;

    if (topOffset != 0 && leftOffset != 0) {
      gsap.fromTo(
        header.current.querySelector(".bi-icon-puce"),
        {
          opacity: 1,
          translateY: topOffset,
          translateX: leftOffset + positionButton.width / 2,
        },
        {
          translateY: -16,
          translateX: 16,
          onComplete: () => {
            gsap.set(".bi-icon-puce", {
              translateY: 0,
              translateX: 0,
              opacity: 0,
            });
          },
        }
      );
    }
  };

  React.useEffect(() => {
    if (
      quantity > 0 &&
      document.querySelector(".bi-product-cart .bi-button.yellow")
    ) {
    }
  }, [quantity]);

  const hideQuantity = () => {
    if (quantity) {
      return <div className="bi-icon-cart">{quantity}</div>;
    } else {
      return <></>;
    }
  };
  return (
    <>
      <IconButton type="cart" />
      {hideQuantity()}
      <div className="bi-icon-puce"></div>
    </>
  );
};

const Header = ({}) => {
  const productsQuantity = useSelector((state) => state.cart.quantity);
  const products = useSelector((state) => state.cart.cart);
  const isConnected = useSelector((state) => state.authentication.connected);
  const menuState = useSelector((state) => state.modals.menu);
  const [navActiveClass, setNavActiveClass] = React.useState(false);
  const refHeader = React.useRef();
  const [isMobileNotif, setIsMobileNotif] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  const dispatch = useDispatch();
  const scroll = useSmoothScroll();

  React.useEffect(() => {
    if (
      productsQuantity > 0 &&
      document.querySelector(".bi-product-cart .bi-button.yellow")
    ) {
      if (isMobile) {
        setIsMobileNotif(true);
      } else {
        setIsMobileNotif(false);
      }
    } else {
      setIsMobileNotif(false);
    }
  });

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 970) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  });

  React.useEffect(() => {
    headerIsMoving();
  }, []);

  React.useEffect(() => {
    dispatch(checkQuantity());
    dispatch(setTotalPrice());
  }, [products]);

  const handleProfileNavigate = (e) => {
    e.preventDefault();
    if (isConnected) {
      navigate("/account/mon-compte");
    } else {
      navigate("/account/connexion");
    }
  };

  React.useEffect(() => {
    if (menuState) {
      scroll?.stop();
      document.body.style.overflow = "hidden";
    } else {
      scroll?.start();
      document.body.style.overflow = "";
    }
  });

  const headerIsMoving = () => {
    let lastScroll = window.scrollY;
    let headerMoving = refHeader.current;

    window.addEventListener("scroll", () => {
      if (window.innerWidth > 769) {
        let currentScroll = window.scrollY;
        if (currentScroll > lastScroll) {
          if (currentScroll > 20) {
            headerMoving.style.top = "-100px";
          } else {
            headerMoving.style.top = "0px";
          }
        } else {
          headerMoving.style.top = "0px";
        }
        lastScroll = currentScroll;
      }
    });
    // var observer = new IntersectionObserver((entries) => {
    //   entries.forEach((entry) => {
    //     if (entry.isIntersecting && document.querySelector(".headerIsMoving")) {
    //       document.querySelector(".headerIsMoving").style.transition =
    //         "all 0.1s";
    //       setNavActiveClass(false);
    //     } else if (document.querySelector(".headerIsMoving")) {
    //       document.querySelector(".headerIsMoving").removeAttribute("style");
    //     }
    //   });
    // });
    // observer.observe(headerNavNotMoving);
  };

  return (
    <header
      ref={refHeader}
      className={`bi-layout bi-layout-header ${menuState ? "menuIsOpen" : ""}`}
    >
      <a href='/' to="/" className="bi-logo-link" name="Retour accueil" aria-label="Expérience" onClick={(e)=>{
        e.stopPropagation();
        e.preventDefault();
        if(window.location.patname !== '/'){
          navigate('/')
        }
      }}>
        <Logo />
      </a>
      <nav>
        <ul className="bi-nav-desktop">
          <li>
            <Link to="/a-propos">La librairie</Link>
          </li>
          <li>
            <Link to="/coups-de-coeur">Coups de coeur</Link>
          </li>
          <li>
            <Link to="/evenements">Événements</Link>
          </li>
          <li>
            <Link to="/store">Boutique</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
        <ul className="bi-nav-desktop">
          <li
            onClick={(e) => {
              handleProfileNavigate(e);
            }}
          >
            <IconButton type="profile" />
          </li>
          <li
            onClick={() => {
              dispatch(setCart(true));
            }}
          >
            <CartIcon quantity={productsQuantity} header={refHeader} />
          </li>
          <li
            onClick={() => {
              dispatch(setSearch(true));
            }}
          >
            <IconButton type="search" />
          </li>
        </ul>
        <ul className="bi-nav-mobile">
          {menuState ? (
            <>
              <li
                onClick={() => {
                  dispatch(setSearch(true));
                  dispatch(setMenu(false));
                }}
              >
                <IconButton type="search-mobile" />
              </li>
              <li
                onClick={() => {
                  dispatch(setMenu(false));
                }}
              >
                <IconButton type="modal-cross" />
              </li>
            </>
          ) : (
            <>
              <li
                onClick={() => {
                  dispatch(setMenu(true));
                }}
              >
                <IconButton type="burger" />
              </li>
              <li
                onClick={(e) => {
                  handleProfileNavigate(e);
                }}
              >
                <IconButton type="profile-mobile" />
              </li>
              <li
                className={isMobileNotif ? "yellowNotif" : ""}
                onClick={() => {
                  dispatch(setCart(true));
                }}
              >
                <IconButton type="cart-mobile" />
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
