import React from "react";
import { useSelector } from "react-redux";

const Logo = () => {
  const menuState = useSelector((state) => state.modals.menu);

  if (!menuState) {
    return (
      <svg
        className="bi-logo"
        width="160"
        height="49"
        viewBox="0 0 160 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.96 9.6H1.6V38H12.96V32.92H7.2V26.16H12.24V21.2H7.2V14.68H12.96V9.6ZM25.8319 9.6C24.2319 17.08 23.7519 19.36 23.6319 21.2C23.5119 19.4 23.0319 17.04 21.4719 9.6H15.4719L18.9919 23.68L15.4719 38H21.4719C23.0719 30.52 23.5119 28.2 23.6319 25.56C23.7519 28.28 24.1919 30.56 25.8319 38H31.7919L28.3519 23.44L31.7919 9.6H25.8319ZM35.1906 9.6V38H40.7906V28.96H41.0706C46.3106 28.96 49.4706 25.28 49.4706 19.12C49.4706 13.16 46.3106 9.6 41.0706 9.6H35.1906ZM40.7906 23.84V14.72H41.0706C42.8306 14.72 43.8706 16.44 43.8706 19.28C43.8706 22.12 42.8306 23.84 41.0706 23.84H40.7906ZM58.6787 0.959998L56.7187 7.32H60.6787L63.7987 0.959998H58.6787ZM64.4787 9.6H53.1187V38H64.4787V32.92H58.7187V26.16H63.7588V21.2H58.7187V14.68H64.4787V9.6ZM82.7106 17.92C82.7106 12.88 79.9906 9.6 74.7906 9.6H68.3906V38H73.9906C73.9906 32.16 73.9506 29.16 73.7106 25.56L76.6706 38H82.7106C80.5506 30.32 79.5106 26.8 78.8706 25.16C81.4706 24.08 82.7106 21.04 82.7106 17.92ZM73.9906 22.28V14.68H74.3906C76.1106 14.68 77.1106 16.24 77.1106 18.6C77.1106 20.52 76.1106 22.28 74.3906 22.28H73.9906ZM92.0359 38V9.6H86.4359V38H92.0359ZM108.185 9.6H96.825V38H108.185V32.92H102.425V26.16H107.465V21.2H102.425V14.68H108.185V9.6ZM121.657 9.6C121.657 18.08 121.777 22.08 122.457 26.2L117.137 9.6H112.097V38H117.297C117.297 29.64 117.177 25.72 116.497 21.56L121.817 38H126.857V9.6H121.657ZM140.746 38.28C141.546 38.28 142.386 38.16 142.986 37.96V33.12C142.746 33.36 142.106 33.48 141.546 33.48C138.306 33.48 136.506 29.84 136.506 23.8C136.506 17.76 138.306 14.12 141.546 14.12C142.106 14.12 142.746 14.24 142.986 14.48V9.64C142.386 9.44 141.546 9.32 140.746 9.32C134.626 9.32 130.946 14.72 130.946 23.8C130.946 32.88 134.626 38.28 140.746 38.28ZM158.727 9.6H147.367V38H158.727V32.92H152.967V26.16H158.007V21.2H152.967V14.68H158.727V9.6Z"
          fill="#1D1D1D"
        />
      </svg>
    );
  } else {
    return (
      <svg
        className="bi-logo"
        width="160"
        height="49"
        viewBox="0 0 160 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.96 9.6H1.6V38H12.96V32.92H7.2V26.16H12.24V21.2H7.2V14.68H12.96V9.6ZM25.8319 9.6C24.2319 17.08 23.7519 19.36 23.6319 21.2C23.5119 19.4 23.0319 17.04 21.4719 9.6H15.4719L18.9919 23.68L15.4719 38H21.4719C23.0719 30.52 23.5119 28.2 23.6319 25.56C23.7519 28.28 24.1919 30.56 25.8319 38H31.7919L28.3519 23.44L31.7919 9.6H25.8319ZM35.1906 9.6V38H40.7906V28.96H41.0706C46.3106 28.96 49.4706 25.28 49.4706 19.12C49.4706 13.16 46.3106 9.6 41.0706 9.6H35.1906ZM40.7906 23.84V14.72H41.0706C42.8306 14.72 43.8706 16.44 43.8706 19.28C43.8706 22.12 42.8306 23.84 41.0706 23.84H40.7906ZM58.6787 0.959998L56.7187 7.32H60.6787L63.7987 0.959998H58.6787ZM64.4787 9.6H53.1187V38H64.4787V32.92H58.7187V26.16H63.7588V21.2H58.7187V14.68H64.4787V9.6ZM82.7106 17.92C82.7106 12.88 79.9906 9.6 74.7906 9.6H68.3906V38H73.9906C73.9906 32.16 73.9506 29.16 73.7106 25.56L76.6706 38H82.7106C80.5506 30.32 79.5106 26.8 78.8706 25.16C81.4706 24.08 82.7106 21.04 82.7106 17.92ZM73.9906 22.28V14.68H74.3906C76.1106 14.68 77.1106 16.24 77.1106 18.6C77.1106 20.52 76.1106 22.28 74.3906 22.28H73.9906ZM92.0359 38V9.6H86.4359V38H92.0359ZM108.185 9.6H96.825V38H108.185V32.92H102.425V26.16H107.465V21.2H102.425V14.68H108.185V9.6ZM121.657 9.6C121.657 18.08 121.777 22.08 122.457 26.2L117.137 9.6H112.097V38H117.297C117.297 29.64 117.177 25.72 116.497 21.56L121.817 38H126.857V9.6H121.657ZM140.746 38.28C141.546 38.28 142.386 38.16 142.986 37.96V33.12C142.746 33.36 142.106 33.48 141.546 33.48C138.306 33.48 136.506 29.84 136.506 23.8C136.506 17.76 138.306 14.12 141.546 14.12C142.106 14.12 142.746 14.24 142.986 14.48V9.64C142.386 9.44 141.546 9.32 140.746 9.32C134.626 9.32 130.946 14.72 130.946 23.8C130.946 32.88 134.626 38.28 140.746 38.28ZM158.727 9.6H147.367V38H158.727V32.92H152.967V26.16H158.007V21.2H152.967V14.68H158.727V9.6Z"
          fill="#FFFFFF"
        />
      </svg>
    );
  }
};

export default Logo;
