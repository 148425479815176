import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSearch } from "../../assets/scripts/store/redux-slices/modals";
import IconButton from "../utils/atoms/IconButton";

import "../../assets/styles/components/modules/search.scss";
import ProductListItem from "./products/ProductListItem";
import EventListItem from "./events/EventListItem";
import CrushListItem from "./crushes/CrushListItem";
import eventBus from "../../assets/scripts/utils/eventBus";

// Animation library
import gsap from "gsap";

const verifyObjectSubfieldNotEmpty = (obj) => {
  const hasProperties = Object.keys(obj).length > 0;

  if (hasProperties) {
    for (let key in obj) {
      if (obj[key].length > 0) {
        return true;
      }
    }

    return false;
  }
};
const ResultList = (props) => {
  if (props.results.length > 0) {
    return (
      <section
        className="bi-search-result_items"
        onWheel={(event) => {
          event.stopPropagation();
        }}
      >
        <p className="bi-title-tertiary">{props.category}</p>
        <ul>
          {props.results &&
            props.results.map((result, index) => {
              if (props.category === "Boutique") {
                return <ProductListItem product={result} type="search" />;
              } else if (props.category === "Événements") {
                return <EventListItem event={result} type="search" />;
              } else if (props.category === "Coups de coeur") {
                return (
                  <CrushListItem
                    crush={result}
                    type="search"
                    desktopWidth="200"
                  />
                );
              } else {
                return (
                  <li>
                    <Link to={`/search/${result.slug}`}>
                      <figure key={index} className="bi-search-result-item">
                        <picture>
                          <img src={result.picture} alt="Text Alternatif"></img>
                        </picture>
                        <p>{result.name}</p>
                      </figure>
                    </Link>
                  </li>
                );
              }
            })}
        </ul>
      </section>
    );
  } else {
    return <></>;
  }
};

const Search = (props) => {
  const dispatch = useDispatch();
  const searchIsOpen = useSelector((state) => state.modals.search);

  // If we are in dev mode fetch the data from https://benevolent-kitten-7203b8.netlify.app/search-index.json
  // else fetch the data from /search-index.json
  const url =
    process.env.NODE_ENV === "development"
      ? "https://benevolent-kitten-7203b8.netlify.app/search-index.json"
      : "/search-index.json";

  const searchInput = useRef();
  const [results, setResults] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [inputEmpty, setInputEmpty] = useState(true);

  // Result schema data structure
  /*
     {
  "products": [
    {
      "name": "Rain",
      "picture": "/_gatsby/file/170fe495066e6f073240282eccea8f03/brighton.jpg?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Frpq9eend%2Fproduction%2F1946132f7a0f2382360955732d4ead128139a222-603x538.jpg",
      "price": "120 €",
      "slug": "rain"
    },
    {
      "name": "Kristen in wave",
      "picture": "/_gatsby/file/da2f72e2043d1f41f8cc11304da0a7c5/sanstitre3.jpg?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Frpq9eend%2Fproduction%2F815a8bab432eec7b8408074c21ec5d49764c1019-603x538.jpg",
      "price": "40",
      "slug": "kristen-in-wave"
    },
    {
      "name": "Sarabande",
      "picture": "/_gatsby/file/4fc9e8d5361e72a65437525bacee9da4/jourdy3.jpg?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Frpq9eend%2Fproduction%2F9bfe4ce26c6d6a92e1dec17208f6b24e8b922acc-603x538.jpg",
      "price": "75",
      "slug": "sarabande"
    }
  ],
  "events": [
    {
      "name": "Priscilla et les enfants d'abord!",
      "picture": "/_gatsby/file/170fe495066e6f073240282eccea8f03/brighton.jpg?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Frpq9eend%2Fproduction%2F1946132f7a0f2382360955732d4ead128139a222-603x538.jpg",
      "date": "2022-05-26",
      "slug": "priscilla-et-les-enfants-dabord"
    }
  ],
  "crushes": [
    {
      "name": "Coup de coeur test",
      "picture": "/_gatsby/file/7752bc764d781134270df3297aa14c3f/sanstitre5.jpg?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2Frpq9eend%2Fproduction%2F852c557076dd4224cf99dacd8f9f30407a77757c-603x538.jpg",
      "date": "22/09/2022",
      "slug": "coup-de-coeur-test"
    }
  ]
}
  */
  React.useEffect(() => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setResults(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
        console.log("error");
      });

    return () => {
      setResults({});
      setSearchResults({});
      setInputEmpty(true);
      setIsLoading(false);
      setIsError(false);
    };
  }, []);

  React.useEffect(() => {
    eventBus.on("routeChange", () => {
      onLeave('rc');
    });
  }, []);

  React.useEffect(() => {
    if (searchIsOpen) {
      onAppear();
    }
  }, [searchIsOpen]);
  const handleInputChange = (event) => {
    const value = event.target.value;
    
    // if results are empty, we don't need to do anything
    if (value.length === 0) {
      setInputEmpty(true);
      return;
    } else {
      setInputEmpty(false);
      // filter events
      const events = results.events || [];
      const filteredEvents = events.filter((event) => {
        return event.name.toLowerCase().includes(value.toLowerCase());
      });

      // filter products
      const products = results.products || [];
      const filteredProducts = products.filter((product) => {
        return product.name.toLowerCase().includes(value.toLowerCase());
      });

      // filter crushes
      const crushes = results.crushes || [];
      const filteredCrushes = crushes.filter((crush) => {
        return crush.name.toLowerCase().includes(value.toLowerCase());
      });

      // set the results
      setSearchResults({
        events: filteredEvents,
        products: filteredProducts,
        crushes: filteredCrushes,
      });
    }
  };

  /**
   * Animation functions
   * - onAppear
   * - onLeave
   * */

  const onAppear = () => {
    eventBus.dispatch("scrollStop");
    let tl = gsap.timeline();
    tl.from(".bi-search-bar", {
      height: "50vh",
      width: "110vw",
      translateX: "-10vw",
      translateY: "-100vh",
      rotate: -4,
    });
    tl.from(".bi-search-bar > div, .bi-search-bar > input", {
      opacity: 0,
      translateY: 40,
      onComplete: () => {
        let input = document.querySelector(".bi-search-bar > input");
        setTimeout(()=>{
          input.focus();
        }, 100);

      },
    });
  };

  const onLeave = (rc) => {
    if(!rc){
      eventBus.dispatch("scrollStart");
    }
    let tl = gsap.timeline();
    let result = document.querySelector(".bi-search-result");
    if (result) {
      tl.to(result, { opacity: 0 });
    }
    tl.to(".bi-search-bar > div, .bi-search-bar > input", {
      opacity: 0,
      translateY: -40,
    });
    tl.to(".bi-search-bar", {
      height: "50vh",
      width: "110vw",
      translateX: "-10vw",
      translateY: "-100vh",
      rotate: 4,
      onComplete: () => {
        let searchButton = document.querySelector(".bi-search-input");
        if (searchButton) {
          searchButton.value = "";
          let event = { target: searchInput.current };
          handleInputChange(event);
          dispatch(setSearch(false));
        }
      },
    });
  };

  if (searchIsOpen) {
    return (
      <div className="bi-search bi-layout">
        <div className={"bi-search-wrapper " + !inputEmpty}>
          <div className="bi-search-bar">
            <input
              type="text"
              placeholder={isLoading ? "Loading..." : "Rechercher"}
              className="bi-search-input bi-title-quaternary"
              ref={searchInput}
              autoFocus
              onChange={(e) => {
                handleInputChange(e);
              }}
              onBlur={(e) => {
                handleInputChange(e);
              }}
            />
            <div
              onClick={() => {
                onLeave();
              }}
            >
              <IconButton type="modal-cross" />
            </div>
          </div>
          {!isLoading && !inputEmpty && (
            <div className="bi-search-result">
              {" "}
              <ResultList
                category="Boutique"
                results={
                  verifyObjectSubfieldNotEmpty(searchResults) || !inputEmpty
                    ? searchResults.products
                    : results.products
                }
              />
              <ResultList
                category="Événements"
                results={
                  verifyObjectSubfieldNotEmpty(searchResults) || !inputEmpty
                    ? searchResults.events
                    : results.events
                }
              />
              <ResultList
                category="Coups de coeur"
                results={
                  verifyObjectSubfieldNotEmpty(searchResults) || !inputEmpty
                    ? searchResults.crushes
                    : results.crushes
                }
              />
            </div>
          )}
          <div
            onClick={() => {
              onLeave();
            }}
            className="bi-search-modal__close"
          ></div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
export default Search;
