import React from "react";
import { useSelector, useDispatch } from "react-redux";
import eventBus from "../../assets/scripts/utils/eventBus";
import {setMenu}  from "../../assets/scripts/store/redux-slices/modals";
import { Link } from "gatsby";
import gsap from "gsap";
import "../../assets/styles/components/modules/menu.scss";

const Menu = () => {
  const menu = useSelector((state) => state.modals.menu);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false)
  //Prendre une seconde variable menu qui s'actualise apres pour fermer le menu

  React.useEffect(() => {
    eventBus.on("pageTransitionEnd", () => {
        onLeave();
    });
  }, []);
  
  React.useEffect(()=>{
    if(menu){
      setIsOpen(true)
    }else{
      onLeave()
    }
  }, [menu])


  React.useEffect(()=>{
    onApear()
  }, [isOpen])


  const onApear = ()=>{
    const tl = gsap.timeline();
    tl.from(".bi-menu-mobile", {
      translateY: "-80vh",
      width: '120vw',
      rotate: -8,
      translateX:'-100px',
    });
    tl.fromTo(".bi-menu-mobile >.bi-nav-desktop > li", {
      opacity: 0,
      translateY: 20,
    },{
      opacity: 1,
      translateY: 0,
      stagger: 0.150
    });
  }

  /**
   * Animation functions
   * - onAppear
   * - onLeave
   * */

  const onLeave = () => {
    const tl = gsap.timeline();
    tl.to(".bi-menu-mobile, .bi-menu-mobile >.bi-nav-desktop > li", {
      opacity: 0,
      onComplete: () => {
        setIsOpen(false);
      },
    });

    
    dispatch(setMenu(false));
  };
  if (!isOpen) {
    return <></>;
  } else {
    return (
      <nav className="bi-menu-mobile">
        <ul className="bi-nav-desktop">
          <li>
            <Link to="/a-propos">La librairie</Link>
          </li>
          <li>
            <Link to="/coups-de-coeur">Coups de coeur</Link>
          </li>
          <li>
            <Link to="/evenements">Événements</Link>
          </li>
          <li>
            <Link to="/store">Boutique</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    );
  }
};

export default Menu;
