import React from "react";
import { Link } from "gatsby";
import "../../assets/styles/components/modules/footer.scss";
import Marquee from "../utils/atoms/Marquee";
import IconButton from "../utils/atoms/IconButton";
import InputNewsletter from "../utils/atoms/InputNewsletter";

const Footer = () => {
  return (
    <footer>
      <Marquee number="8">EXPÉRIENCE</Marquee>
      <div className="footer-content_center">
        <div className="footer-content_center--socials">
          <h4>Suivez-nous</h4>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/librairie.experience"
                target="_blank"
                rel="noreferrer"
                name="Facebook"
                aria-label="Facebook"
              >
                <IconButton type="facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/librairieexperience/"
                target="_blank"
                rel="noreferrer"
                name="Instagram"
                aria-label="Instagram"
              >
                <IconButton type="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC-3V_p3w2PkIGDMU08f1KyQ"
                target="_blank"
                rel="noreferrer"
                name="Youtube"
                aria-label="Youtube"
              >
                <IconButton type="youtube" />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-content_center--newsletter">
          <h4>Newsletter</h4>
          <InputNewsletter />
        </div>
        <div className="footer-content_center--adress">
          <h4>Adresse</h4>
          <p className="small">
            5 Place Antonin Poncet, <br/>
            69002 Lyon
          </p>
          <br/>
          <p className="small">
            10h-19h du lundi au samedi
          </p>
        </div>
      </div>
      <div className="footer-content_bottom">
        <ul className="small">
          <li>
            <p className="small">© 2022 Librairie Expérience</p>
          </li>
          <li>
            <Link to="/cgv/">Conditions générales de ventes</Link>
          </li>
          <li>
            <Link to="/mentions-legales/">Mentions légales</Link>
          </li>
          <li>
            <Link to="/faq/">FAQ</Link>
          </li>
        </ul>
        <p className="small">
          Réalisé par{" "}
          <a href="https://bihua.fr" target="_blank" rel="noreferrer">
            Bihua
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
